{
  "name": "frontend-pdc-workspace",
  "version": "24.10.1",
  "scripts": {
    "start": "npm-run-all clean --parallel parashift-shared:watch app-parashift-io:start-waiton",
    "start:embedded": "npm-run-all clean --parallel parashift-shared:watch embedded-parashift-io:start-waiton",
    "build": "run-s parashift-shared:build embedded-parashift-io:build app-parashift-io:build",
    "test": "run-p embedded-parashift-io:test app-parashift-io:test",
    "test-coverage": "run-p parashift-shared:test-coverage embedded-parashift-io:test-coverage app-parashift-io:test-coverage",
    "lint": "ng lint",
    "app-parashift-io:start-waiton": "wait-on dist/parashift/shared/package.json && npm run app-parashift-io:start",
    "app-parashift-io:start": "ng serve app-parashift-io",
    "app-parashift-io:build": "ng build app-parashift-io",
    "app-parashift-io:watch": "ng build app-parashift-io --watch --configuration dev",
    "app-parashift-io:test": "ng test app-parashift-io",
    "app-parashift-io:test-coverage": "ng test app-parashift-io --code-coverage",
    "embedded-parashift-io:start-waiton": "wait-on dist/parashift/shared/package.json && npm run embedded-parashift-io:start",
    "embedded-parashift-io:start": "ng serve embedded-parashift-io",
    "embedded-parashift-io:build": "ng build embedded-parashift-io",
    "embedded-parashift-io:watch": "ng build embedded-parashift-io --watch --configuration dev",
    "embedded-parashift-io:test": "ng test embedded-parashift-io",
    "embedded-parashift-io:test-coverage": "ng test embedded-parashift-io --code-coverage",
    "parashift-shared:build": "ng build @parashift/shared",
    "parashift-shared:watch": "ng build @parashift/shared --watch --configuration production",
    "clean": "rimraf dist",
    "sentry:app-parashift-io": "sh sentry/app-parashift-io.artifacts.sh",
    "sentry:embedded-parashift-io": "sh sentry/embedded-parashift-io.artifacts.sh"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^17.0.2",
    "@angular/animations": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/elements": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@ctrl/ngx-codemirror": "^7.0.0",
    "@fortawesome/angular-fontawesome": "0.14.x",
    "@fortawesome/fontawesome-pro": "^6.5.2",
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/pro-duotone-svg-icons": "^6.5.2",
    "@fortawesome/pro-light-svg-icons": "^6.5.2",
    "@fortawesome/pro-regular-svg-icons": "^6.5.2",
    "@fortawesome/pro-solid-svg-icons": "^6.5.2",
    "@maskito/angular": "^2.4.0",
    "@maskito/core": "^2.4.0",
    "@maskito/kit": "^2.4.0",
    "@ngx-formly/bootstrap": "^6.3.4",
    "@ngx-formly/core": "^6.3.4",
    "@parashift/ngx-airbrush": "^15.0.0",
    "@parashift/ngx-bootstrap-multiselect": "15.0.0",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular": "^8.33.1",
    "@sentry/cli": "^2.36.6",
    "activestorage": "^5.2.8-1",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.3.3",
    "chart.js": "4.4.3",
    "codemirror": "^5.65.7",
    "color": "4.2.3",
    "core-js": "^3.26.1",
    "date-fns": "^3.6.0",
    "date-fns-tz": "^3.1.3",
    "fuzzysort": "^2.0.1",
    "iso-639-1": "^2.1.9",
    "jshint": "^2.13.6",
    "jwt-decode": "^4.0.0",
    "ng-keyboard-shortcuts": "13.0.8",
    "ngx-bootstrap": "^12.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-device-detector": "^7.0.0",
    "ngx-drag-drop": "^17.0.0",
    "ngx-image-cropper": "^8.0.0",
    "ngx-toastr": "^18.0.0",
    "pace-js": "^1.2.4",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "userflow.js": "2.1.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.5.1",
    "@angular-eslint/eslint-plugin": "17.5.1",
    "@angular-eslint/eslint-plugin-template": "17.5.1",
    "@angular-eslint/schematics": "17.5.1",
    "@angular-eslint/template-parser": "17.5.1",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.0",
    "@angular/localize": "^17.3.11",
    "@types/activestorage": "^5.2.7",
    "@types/codemirror": "^5.60.15",
    "@types/color": "^3.0.6",
    "@types/core-js": "^2.5.8",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.14.12",
    "@types/wicg-entries-api": "^2020.8.2",
    "@typescript-eslint/eslint-plugin": "7.10.0",
    "@typescript-eslint/parser": "7.10.0",
    "eslint": "^8.57.0",
    "eslint-plugin-deprecation": "^3.0.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^17.3.0",
    "npm-run-all": "^4.1.5",
    "rimraf": "^5.0.7",
    "typescript": "~5.4.2",
    "wait-on": "^7.2.0"
  },
  "engines": {
    "node": "20.15.1"
  }
}
